import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import facebook from '../../images/facebook.svg'
import instagram from '../../images/instagram.svg'
import mail from '../../images/envelope-fill.svg'
import payment from '../../images/Payment-methods.png'

const Footer = () => {
    const column1 = ["Who we are", 
    "How we print and manufacture",
    "What makes us different",
    "Who we work for",
    "Join the family",
    "Terms and Conditions"]

    const column2 = ["My Account",
        "Size Chart",
        "Frequently Asked Questions",
        "Blog",
        "Privacy Policy"]
  return (
    <div className='Footer' >
        <div className='FooterTop' >
            <div>
                <p> About Awesome Shirts </p>
                {column1.map(i => <Link key={i} to={i.split(" ").join("-")} > {i} </Link>)}
            </div>
            <div>
                <p> Support </p>
                {column2.map(i => <Link key={i} to={i.split(" ").join("-")} > {i} </Link>)}
                <Link to='mailto:ciyr.connect@gmail.com' > Contact Us </Link>
            </div>
        </div>
        <div className='line' ></div>
        <div className='FooterMiddle' >
            <div className='socialIcon' > <img src={facebook} alt="facebook" /> </div>
            <div className='socialIcon' > <img src={instagram} alt="instagram" /> </div>
            <div className='socialIcon' > <img src={mail} alt="mail" /> </div>
        </div>
        <div className='line' ></div>
        <div className='FooterBottom' >
            <span>We Accept</span>
            <img src={payment} alt="payment options" />
        </div>
        <div className='copyright' >
            <span> 
                {`Copyright © ${new Date().getFullYear()} Awesome Shirts. All Rights Reserved. | `}
                <Link to="sitemap" >Sitemap</Link>
            </span>
        </div>
    </div>
  )
}

export default Footer