import React from 'react'
import './ProductCard.css'

const ProductCard = ({image, title, owner, price, discount, discountPerc}) => {
    const fullWidth = window.screen.width
    const pix = fullWidth*0.23/14
    const titleLength = Math.floor(0.067)
    const trim = (str) => {
        for(let i = titleLength;i > 0; i--){
            if(str[i]===" "){
                return str.slice(0,i)+"..."
            }
        }
        return titleLength
    }
    const trimmed = (str) => {
        if(str.length > titleLength){
            return trim(str)
        } else {
            return str
        }
    }

    const style = {
        backgroundImage:`url(${image})`,
        backgroundPosition: '50% 30%',
        backgroundSize: 'cover'
    }
  return (
    <div className='ProductCard' >
        <div className='productImage' style={style} ></div>
        <span className='title' title={title} > {title} </span>
        <div className='priceLine' >
            <span className='price' > {price} </span>
            <span className='discount' > {discount} </span>
            <span className='discountPerc' > {discountPerc} </span>
        </div>
        <span className='owner' > {owner} </span>
        <button> <i className="bi bi-plus"></i> Add to basket </button>
    </div>
  )
}

export default ProductCard