import React, {useState, useRef} from 'react'
import { useNavigate } from 'react-router-dom'
import './Toolbar.css'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

const Toolbar = ({
  setSelectedImage, 
  setIsImageSelected, 
  saveClick,
  setStartedEditText,
  setText,
  updateImage,
  graphicPointer,
  imagesLength,
  setGraphicPointer,
  revert
}) => {
  
  const navigate = useNavigate()
  const [toolbar2, setToolbar2] = useState(false)
  const inputRef = useRef()
  
  const handleImageUpload = (event) => {
    setToolbar2('image')
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setSelectedImage({file:reader.result, name:file.name});
      setIsImageSelected(true)
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const okayImageClick = () => {
    setToolbar2(false)
    setIsImageSelected(false)
    updateImage()
  }

  const clearImageClick = () => {
    setSelectedImage(null)
    setIsImageSelected(false)
    setToolbar2(false)
  }

  const handleSave = () => {
    saveClick()
  }

  const exitClick = () => {
    navigate('/')
  }

  const addTextClick = () => {
    setStartedEditText(true)
    setToolbar2('text')
  }

  const okayTextClick = () => {
    setToolbar2(false)
    setStartedEditText(false)
    updateImage()
  }

  const clearTextClick = () => {
    setText('')
    setStartedEditText(false)
    setToolbar2(false)
  }  

  const undo = () => {
    setGraphicPointer(prev => prev+1)
  }

  const redo = () => {
    setGraphicPointer(prev => prev-1)
  }

  const revertClick = () => {
    confirmAlert({
      title: 'Confirm to revert',
      message: 'Are you sure you want to discard all progress?',
      buttons: [
        {
          label: 'Yes',
          onClick: revert
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  return (
    <div className='Toolbar' >
      
        {toolbar2===false?
        <>
        <div className='left' >
        <button onClick={exitClick} > <i className="bi bi-arrow-left"></i> Exit </button>
    </div>

        <div className='center' >
          <button onClick={undo} disabled={imagesLength-1===graphicPointer} ><i className = "bi bi-arrow-counterclockwise"></i> Undo </button>
          <button onClick={redo} disabled={graphicPointer===0} ><i className="bi bi-arrow-clockwise"></i> Redo </button>
        <button> <i className = "bi bi-palette"></i> Shirt colour </button>
        <button onClick={() => inputRef.current.click()} > <i className = "bi bi-file-image"></i> Add photo </button>
        <input ref={inputRef} className='inputForFile' type="file" accept="image/*" onChange={handleImageUpload} />
        <button onClick={addTextClick} > <i className = "bi bi-textarea-t"></i> Add text </button>
        <button onClick={revertClick} > <i className="bi bi-trash3"></i> Revert </button>
        </div>
        <div className='right' >
        <button onClick={handleSave} > Save <i className="bi bi-box-arrow-down"></i> </button>
    </div>
    </>
      :  
      <>
      <div></div>
      <div className='center' >
          <button onClick={(toolbar2==='image'?okayImageClick:okayTextClick)} > <i className="bi bi-check-lg"></i> Okay </button>
          <button onClick={(toolbar2==='image'?clearImageClick:clearTextClick)} > <i className="bi bi-x-lg"></i> Clear </button>
        </div>
        <div></div>
      </>
      }
        
    </div>
  )
}

export default Toolbar