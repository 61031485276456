import React, {useState} from 'react'
import './TextPosition.css'
import { namedColors } from './colours'

const TextPosition = ({
    appliedTextStyle,
    setAppliedTextStyle,
    checkAndResizeTextarea
}) => {
    const [colourInput, setColourInput] = useState('')
    const colours = ['A0C49D','22A699','F2BE22','F24C3D','FF78C4','E1AEFF','FFECEC','0E2954','001C30','FF6666','F1C27B','A76F6F']
    const zoomPercent = Number(appliedTextStyle.fontSize.slice(0, -2))
    const widthPercent = Number(appliedTextStyle.width.slice(0, -1))
    const selectedAlign = appliedTextStyle.textAlign

    const zoomOut = () => {
        setAppliedTextStyle(prev => {
            let newStyles = {...prev}
            newStyles.fontSize = ((zoomPercent - 1) + 'px')
            return newStyles
        })
        // checkAndResizeTextarea() not effective
    }

    const zoomIn = () => {
        setAppliedTextStyle(prev => {
            let newStyles = {...prev}
            newStyles.fontSize = ((zoomPercent + 1) + 'px')
            return newStyles
        })
        checkAndResizeTextarea()
    }

    const moveUp = () => {
        setAppliedTextStyle(prev => {
            let newStyles = {...prev}
            newStyles.top = (Number(prev.top.slice(0, -2)) -1) + 'px'
            return newStyles
        })
    }

    const moveDown = () => {
        setAppliedTextStyle(prev => {
            let newStyles = {...prev}
            newStyles.top = (Number(prev.top.slice(0, -2)) +1) + 'px'
            return newStyles
        })
    }

    const moveLeft = () => {
        setAppliedTextStyle(prev => {
            let newStyles = {...prev}
            newStyles.left = (Number(prev.left.slice(0, -2)) -1) + 'px'
            return newStyles
        })
    }

    const moveRight = () => {
        setAppliedTextStyle(prev => {
            let newStyles = {...prev}
            newStyles.left = (Number(prev.left.slice(0, -2)) +1) + 'px'
            return newStyles
        })
    }

    const colourClick = (i) => {
        if(i.length>5){
            setAppliedTextStyle(prev => {
                let newStyles = {...prev}
                newStyles.color = (i[0]==='#'?i:'#'+i)
                return newStyles
            })
            setColourInput(i)
        }
    }

    const increaseWidth = () => {
        setAppliedTextStyle(prev => {
            let newStyles = {...prev}
            newStyles.width = (widthPercent +1) + '%'
            return newStyles
        })
    }

    const decreaseWidth = () => {
        setAppliedTextStyle(prev => {
            let newStyles = {...prev}
            newStyles.width = (widthPercent -1) + '%'
            return newStyles
        })
        checkAndResizeTextarea()
    }

    const leftAlign = () => {
        setAppliedTextStyle(prev => {
            let newStyles = {...prev}
            newStyles.textAlign = 'left'
            return newStyles
        })
    }

    const rightAlign = () => {
        setAppliedTextStyle(prev => {
            let newStyles = {...prev}
            newStyles.textAlign = 'right'
            return newStyles
        })
    }

    const centerAlign = () => {
        setAppliedTextStyle(prev => {
            let newStyles = {...prev}
            newStyles.textAlign = 'center'
            return newStyles
        })
    }

  return (
    <div className='TextPosition' >
        <div className='zoom' >
            <div> Font Size- </div>
            <button onClick={zoomOut} > <i className="bi bi-dash"></i> </button>
            <span> {zoomPercent} </span>
            <button onClick={zoomIn} > <i className="bi bi-plus"></i> </button>
        </div>
        <div className='width' >
            <div> Textbox Length- </div>
            <button onClick={decreaseWidth} > <i className="bi bi-dash"></i> </button>
            <span> {widthPercent} </span>
            <button onClick={increaseWidth} > <i className="bi bi-plus"></i> </button>
        </div>
        <div className='align' >
            <div> Text Align- </div>
            <button onClick={leftAlign} className={selectedAlign==='left' ? 'selectedOption':''} > <i className="bi bi-text-left"></i> </button>
            <button onClick={centerAlign} className={selectedAlign==='center' ? 'selectedOption':''} > <i className="bi bi-text-center"></i> </button>
            <button onClick={rightAlign} className={selectedAlign==='right' ? 'selectedOption':''} > <i className="bi bi-text-right"></i> </button>
        </div>
        <div className='move' >
            <button onClick={moveUp} > <i className="bi bi-arrow-up"></i> </button>
            <div className='leftAndRight' >
            <button onClick={moveLeft} > <i className="bi bi-arrow-left"></i> </button> 
            <button onClick={moveRight} > <i className="bi bi-arrow-right"></i> </button>
            </div>
            <button onClick={moveDown} > <i className="bi bi-arrow-down"></i> </button>
        </div>
        <div className='colours' >
        
<span>Choose a colour</span>
<div className='colourDivs' >
    {colours.map(i => <React.Fragment key={i}><div onClick={() => colourClick(i)} style={{backgroundColor:'#'+i}} ></div></React.Fragment>)}
</div>
<div className='colourInputGroup' >
<input placeholder='Or enter hex code' value={colourInput} onChange={(e) => setColourInput(e.target.value)} />
<button onClick={() => colourClick(colourInput)} > <i className="bi bi-check"></i> </button>
</div>
        </div>
    </div>
  )
}

export default TextPosition