import React, {useState} from 'react'
import './Search.css'

const Search = () => {
    const [text, setText] = useState("")
    const [inputClick, setInputClick] = useState(false)
    const handleClick = () => {
        alert("searching for " + text)
    }
    const handleChange = (e) => {
        setText(e.target.value)
    }
  return (
    <div className={`Search ${inputClick?"inputFocus":""}`} >
        <input type="text" value={text} onChange={(e) => handleChange(e)} placeholder='Search...' onFocus={() => setInputClick(true)} onBlur={() => setInputClick(false)} />
        <button onClick={handleClick} > <i className="bi bi-search"></i> </button>
    </div>
  )
}

export default Search