import React from 'react'
import './Products.css'
import productImage from '../../images/productImage.webp'
import kurta2 from '../../images/kurta2.avif'
import kurta3 from '../../images/kurta3.avif'
import kurta4 from '../../images/kurta4.avif'
import ProductCard from '../ProductCard'

const Products = () => {
    const productData = {
        title:"Sky Blue Nehru Modi Jacket with Kurta Pajama Set",
        owner:" IndyaEthnicStore",
        price:"₹ 4,707",
        discount:"₹ 6,276",
        discountPerc:"(25% Off)"
    }
    const productArray = [...Array(10).keys()].map(i => {
        return {
            ...productData,
            image:(i%4===0?productImage:(i%4===1?kurta2:(i%4===2?kurta3:kurta4)))
        }
    })
  return (
    <div className='Products' >
        {productArray.map((i,index) => <ProductCard 
        key={index}
        image={i.image} 
        title={i.title} 
        owner={i.owner} 
        price={i.price} 
        discount={i.discount} 
        discountPerc={i.discountPerc} 
        />)}
    </div>
  )
}

export default Products