import React, {useState, useEffect} from 'react'
import './Area.css'
import Position from '../create.position'
import white from '../../images/white.jpg'
import TextPosition from '../create.textPosition'

const Area = ({
    selectedImage, 
    isImageSelected, 
    imageRef, 
    startedEditText, 
    setText, 
    text,
    textInputRef,
    updatedImage,
    graphicPointer
}) => {
    
    const defaultImageStyle = {
        top:'40%',
        left:'50%',
        width:'50%'
    }

    const defaultTextStyle = {
        top:'25%',
        left:'50%',
        fontSize:'20px',
        color:'black',
        width:'50%',
        textAlign:'center'
    }

    const [appliedImageStyles, setAppliedImageStyles] = useState(defaultImageStyle)
    const [appliedTextStyle, setAppliedTextStyle] = useState(defaultTextStyle)
    const [originalPosition, setOriginalPosition] = useState(false)

    const increaseRow = (e) => {
        const textAreaEl = textInputRef.current
        if(e.key==='Enter'){
            textAreaEl.style.height = textAreaEl.scrollHeight + 'px'
        }
    }

    const checkAndResizeTextarea = () => {
        const textAreaEl = textInputRef.current
        setTimeout(() => {
            textAreaEl.style.height = textAreaEl.scrollHeight + 'px'
        }, 100);
    }

    let x = 0, 
    y = 0, 
    mousedown = false; 

// div event mousedown 
const divMouseDown = (e) => { 
    const div = document.querySelector('.movable')
    if(div){
        // mouse state set to true 
    mousedown = true; 
    // subtract offset 
    x = div.offsetLeft - e.clientX; 
    y = div.offsetTop - e.clientY;
    } 
}

// div event mouseup 
const divMouseUp = (e) => { 
    // mouse state set to false 
    mousedown = false; 
}

// element mousemove to stop 
const elemMouseMove = (e) => { 
    const div = document.querySelector('.movable')
    if(div){
        // Is mouse pressed 
    if (mousedown) { 
        // Now we calculate the difference upwards 
        div.style.left = e.clientX + x + 'px'; 
        div.style.top = e.clientY + y + 'px'; 
    } 
    }
}

const elemMouseUp = () => {
    const div = document.querySelector('.movable')

    if(div){
        startedEditText && setAppliedTextStyle(prev => {
            const newObj = {...prev}
            newObj.top = div.style.top
            newObj.left = div.style.left
            return newObj
        })
    
        isImageSelected && setAppliedImageStyles(prev => {
            const newObj = {...prev}
            newObj.top = div.style.top
            newObj.left = div.style.left
            return newObj
        })
    }
}

    useEffect(() => {
        const el = document.querySelector('.movable')
        setAppliedImageStyles(defaultImageStyle) //restore zoom and position when image changes
        selectedImage && setAppliedImageStyles(prev => {
            const newObj = {...prev}
            newObj.top = el.offsetTop + 'px'
            newObj.left = el.offsetLeft + 'px'
            return newObj
        })
    }, [selectedImage])

    useEffect(() => {
        if(text==='' && startedEditText){
            const el = document.querySelector('.movable')
            let defaultTextStyle = {
                top:el.offsetTop + 'px',
                left:el.offsetLeft + 'px',
                fontSize:'20px',
                color:'black',
                width:'50%',
                textAlign:'center'
            }
            
            if(!originalPosition){
                setOriginalPosition({
                    top:el.offsetTop + 'px',
                    left:el.offsetLeft + 'px'
                })
            } else {
                defaultTextStyle.top=originalPosition.top
                defaultTextStyle.left=originalPosition.left
            }

            setAppliedTextStyle(defaultTextStyle)
        }
        
    }, [startedEditText])

    const handleTextInput = (e) => {
        setText(e.target.value)
        checkAndResizeTextarea()
    }
    
  return (
    <div className='Area' >
        
        <div className='graphic' ref={imageRef} onMouseMove={(e) => elemMouseMove(e)} onMouseUp={elemMouseUp} >
            <img className='tshirt' src={updatedImage[updatedImage.length-1-graphicPointer]} alt="" />
            
            {selectedImage && 
            <img className={'addedImage movable' + (isImageSelected?' selectedImage':'')} 
            style={appliedImageStyles} 
            onMouseDown={(e) => divMouseDown(e)}
            onMouseUp={(e) => divMouseUp(e)}
            draggable='false'
            src={selectedImage.file} alt="" />
            }
            
            {startedEditText &&
            <textarea 
            ref={textInputRef} 
            rows={1}
            className='addedTextInput movable' 
            value={text} 
            onChange={(e) => handleTextInput(e)} 
            style={appliedTextStyle}
            onKeyUp={(e) => increaseRow(e)}
            onMouseDown={(e) => divMouseDown(e)}
            onMouseUp={(e) => divMouseUp(e)}
            />
            }
            
            {(!startedEditText && text!=='') && 
                <div className='addedTextSpan' style={appliedTextStyle} > {text} </div>
            }
        </div>
        
        {selectedImage && isImageSelected?
        <Position
        appliedImageStyles={appliedImageStyles} 
        setAppliedImageStyles={setAppliedImageStyles} 
        />
    :null}

        {startedEditText && <TextPosition
            appliedTextStyle={appliedTextStyle}
            setAppliedTextStyle={setAppliedTextStyle}
            checkAndResizeTextarea={checkAndResizeTextarea}
        />}

    </div>
  )
}

export default Area