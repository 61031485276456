import React from 'react'
import './Home.css'
import Banner from '../../components/Banner'
import Products from '../../components/Products'

const Home = () => {
  return (
    <div className='Home' >
        <Banner />
        <Products />
    </div>
  )
}

export default Home