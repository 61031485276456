import React from 'react'
import './Position.css'

const Position = ({appliedImageStyles, setAppliedImageStyles}) => {
    const zoomPercent = Number(appliedImageStyles.width.slice(0, -1)) * 2

    const zoomOut = () => {
        setAppliedImageStyles(prev => {
            let newStyles = {...prev}
            newStyles.width = (((zoomPercent / 2) - 1) + '%')
            return newStyles
        })
    }

    const zoomIn = () => {
        setAppliedImageStyles(prev => {
            let newStyles = {...prev}
            newStyles.width = (((zoomPercent / 2) + 1) + '%')
            return newStyles
        })
    }

    const moveUp = () => {
        setAppliedImageStyles(prev => {
            let newStyles = {...prev}
            newStyles.top = (Number(prev.top.slice(0, -2)) -1) + 'px'
            return newStyles
        })
    }

    const moveDown = () => {
        setAppliedImageStyles(prev => {
            let newStyles = {...prev}
            newStyles.top = (Number(prev.top.slice(0, -2)) +1) + 'px'
            return newStyles
        })
    }

    const moveLeft = () => {
        setAppliedImageStyles(prev => {
            let newStyles = {...prev}
            newStyles.left = (Number(prev.left.slice(0, -2)) -1) + 'px'
            return newStyles
        })
    }

    const moveRight = () => {
        setAppliedImageStyles(prev => {
            let newStyles = {...prev}
            newStyles.left = (Number(prev.left.slice(0, -2)) +1) + 'px'
            return newStyles
        })
    }

  return (
    <div className='Position' >
        <div className='zoom' >
            <button onClick={zoomOut} > <i className="bi bi-dash"></i> </button>
            <span> {zoomPercent + '%'} </span>
            <button onClick={zoomIn} > <i className="bi bi-plus"></i> </button>
        </div>
        <div className='move' >
            <button onClick={moveUp} > <i className="bi bi-arrow-up"></i> </button>
            <div className='leftAndRight' >
            <button onClick={moveLeft} > <i className="bi bi-arrow-left"></i> </button> 
            <button onClick={moveRight} > <i className="bi bi-arrow-right"></i> </button>
            </div>
            <button onClick={moveDown} > <i className="bi bi-arrow-down"></i> </button>
        </div>
    </div>
  )
}

export default Position