import React from 'react'
import './Banner.css'
import Image from '../../images/Banner2.webp'
import banner2 from '../../images/banner2.jpg'

const Banner = () => {
  const style = {
    backgroundImage:`url(${Image})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover'
}
  return (
    <div className='Banner' style={style} >
    </div>
  )
}

export default Banner