import React from 'react'
import './CartIcon.css'

const CartIcon = ({quantity}) => {
  return (
    <div className='CartIcon' >
        <div>
        <i className="bi bi-cart"></i>
        </div>
        <div>
        <span> {quantity} </span>
        </div>
    </div>
  )
}

export default CartIcon