import React, { Fragment } from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'
import CartIcon from '../CartIcon'
import Search from '../Search'
import Logo from '../../images/ciyr logo.png'

const Navbar = () => {
    return (
        <div className='Navbar' >
            <div className='strip1' >

                <div className='logo' >
                    <Link to="/"> <img src={Logo} alt='' /> </Link>
                </div>

                <div className='location' >
                    <div className='locationIcon' >
                        <i className="bi bi-geo-alt"></i>
                    </div>
                    <div className='locationText' >
                        <span className='smallText' >
                            Hello
                        </span>
                        <span className='largeText' >
                            Select your address
                        </span>
                    </div>
                </div>

                <Search />

                <div className='signupButton' >
                    <Link to="signup">
                        <span className='smallText' >New user?</span>
                        <span className='largeText' > Sign Up </span>
                    </Link>
                </div>

                <div className='loginButton' >
                    <Link to="login">
                        <span className='largeText' > Login <i className="bi bi-box-arrow-in-right"></i> </span>
                    </Link>
                </div>

                <div className='ordersButton' >
                    <Link to="orders" >
                        <span className='smallText' > Returns </span>
                        <span className='largeText' > & Orders </span>
                    </Link>
                </div>

                <span className='cartLink' >
                    <Link to={"cart"} >
                        <CartIcon quantity={"2"} />
                        <span className='cartSpan' >Cart</span>
                    </Link>
                </span>

            </div>
            <div className='strip2' >

                <div className='allButton' >
                    <i className="bi bi-list"></i>
                    <span className='largeText' >All</span>
                </div>

                <Link to="about-us"> About Us </Link>
                <Link to="sell-with-us" > Sell with Us </Link>
                <span className='boldText' ><Link to="create" > Create </Link></span>
                <Link to="template" > Template </Link>
                <div className='contactUs' >
                <Link to="mailto:ciyr.connect@gmail.com" >
                    Contact Us
                    <span>ciyr.connect@gmail.com</span>
                </Link>
                </div>
                <Link to="FAQs" > {"Frequently asked questions(FAQs)"} </Link>

            </div>
            <div className='strip2Mobile' >
                <i className="bi bi-list"></i>
                <div className='logoMobile'>
                <Link to="/"> <img src={Logo} alt='' /> </Link>
                </div>
            </div>
        </div>
    )
}

export default Navbar