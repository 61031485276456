import React, {useState, useRef, useEffect} from 'react'
import './Create.css'
import html2canvas from 'html2canvas';
import Toolbar from '../../components/create.toolbar'
import Area from '../../components/create.area'
import white from '../../images/white.jpg'

const Create = () => {
    const imageRef = useRef()
    const textInputRef = useRef()
    const [updatedImage, setUpdatedImage] = useState([white])
    const [selectedImage, setSelectedImage] = useState(null) 
    const [graphicPointer, setGraphicPointer] = useState(0)
    const [isImageSelected, setIsImageSelected] = useState(true) //To enable position box when an image is selected
    const [startedEditText, setStartedEditText] = useState(false) //To give style to editing text
    const [text, setText] = useState('')

    const saveClick = () => {
            html2canvas(imageRef.current).then(canvas =>
                {    
                   // Convert our canvas to a data URL
          let canvasUrl = canvas.toDataURL();
          // Create an anchor, and set the href value to our data URL
          const createEl = document.createElement('a');
          createEl.href = canvasUrl;
      
          // This is the name of our downloaded file
          createEl.download = 'White Tshirt with ' + (selectedImage?selectedImage.name:text + '.png');
      
          // Click the download button, causing a download, and then remove it
          createEl.click();
          createEl.remove();
                });
      }

    const updateImage = () => {
      setTimeout(() => {
        html2canvas(imageRef.current).then(canvas =>
          {    
             // Convert our canvas to a data URL
    let canvasUrl = canvas.toDataURL();
    setUpdatedImage(prev => [...prev,canvasUrl])
    setText('')
    setSelectedImage(null)
    setGraphicPointer(0)
          });
      }, 100)
    }

    const revert = () => {
      setUpdatedImage([white])
      setGraphicPointer(0)
    }

    useEffect(() => {
        startedEditText && textInputRef.current.focus()
    }, [startedEditText])

  return (
    <div className='Create' >
        
        <Toolbar 
        setSelectedImage={setSelectedImage} 
        setIsImageSelected={setIsImageSelected} 
        saveClick={saveClick}
        setStartedEditText={setStartedEditText}
        setText={setText}
        updateImage={updateImage}
        graphicPointer={graphicPointer}
        imagesLength={updatedImage.length}
        setGraphicPointer={setGraphicPointer}
        revert={revert}
        />
        
        <Area 
        selectedImage={selectedImage} 
        isImageSelected={isImageSelected}
        imageRef={imageRef}
        startedEditText={startedEditText}
        setText={setText}
        text={text}
        textInputRef={textInputRef}
        updatedImage={updatedImage}
        graphicPointer={graphicPointer}
        />

    </div>
  )
}

export default Create